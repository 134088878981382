import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { usePromptTemplateTagsQuery } from '@/services/cms/prompt-template-tags/prompt-template-tags.service.hook';
import { PromptTemplateTag } from '@/services/cms/prompt-template-tags/prompt-template-tags.types';

import { PromptTemplateTagList } from '../components/PromptTemplateTagList';

interface PromptTemplateTagListContainerProps {
  onSelectTag: (tag: PromptTemplateTag) => void;
}

export function PromptTemplateTagListContainer({ onSelectTag }: PromptTemplateTagListContainerProps) {
  const { data: tags } = usePromptTemplateTagsQuery();
  const isMobile = useIsMobileViewport();

  return (
    <PromptTemplateTagList.Root mobile={isMobile}>
      {tags
        ?.filter(({ hidden }) => !hidden)
        .map((tag, i) => (
          <PromptTemplateTagList.Item
            id={`text-prompt-dialog-category-${i}-button`}
            key={tag.value}
            tag={tag}
            onSelectTag={onSelectTag}
            mobile={isMobile}
          />
        ))}
    </PromptTemplateTagList.Root>
  );
}
