import { useQuery } from '@tanstack/react-query';

import { useServices } from '@/Services';

export function usePromptTemplateTagsQuery() {
  const { promptTemplateTagService } = useServices();
  return useQuery({
    queryKey: ['prompt-template-tags'],
    queryFn: () => promptTemplateTagService.getTags(),
  });
}

export function usePromptTemplateTagQuery(id: string | null | undefined) {
  const { promptTemplateTagService } = useServices();
  return useQuery({
    queryKey: ['prompt-template-tags', id],
    queryFn: () => {
      if (id) {
        return promptTemplateTagService.getTag(id);
      }
      return null;
    },
    enabled: !!id,
  });
}
