import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { PromptTemplateTag } from '@/services/cms/prompt-template-tags/prompt-template-tags.types';

import { Hero, HeroCategory, HeroImage } from './PromptTemplateTagHero.styles';

type PromptTemplateTagHeroProps = {
  tag: PromptTemplateTag;
};

export function PromptTemplateTagHero({ tag }: PromptTemplateTagHeroProps) {
  const isMobile = useIsMobileViewport();

  return (
    <Hero>
      <HeroCategory>
        <RMText type="sans" size={isMobile ? 'xxs' : 'xs'} bold color="on-surface-tertiary">
          QUESTIONS ABOUT
        </RMText>

        <RMText type="serif" size={isMobile ? 'l' : 'xxl'} color="on-surface-primary">
          {tag.title}
        </RMText>
      </HeroCategory>

      <HeroImage src={tag.imageUrl} alt="Tag image" />
    </Hero>
  );
}
