import { styled } from '@linaria/react';

export const Item = styled.div`
  position: relative;
  // 2 items per row
  flex: calc(50% - var(--spacing-sm));
  flex-grow: 0;
  padding: var(--spacing-xl);
  background-color: var(--surface-dim);
  border: 1px solid var(--border-hairline);
  border-radius: var(--radius-smooth);
  display: flex;
  justify-content: space-between;
  align-items: self-end;
  cursor: pointer;
  overflow: hidden;
  user-select: none;

  img {
    height: 8rem;
    width: 8rem;
    object-fit: cover;
  }

  &:after {
    position: absolute;
    content: ' ';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  :hover,
  :active,
  &[data-selected='true'] {
    &:after {
      background-color: var(--darken-neutral-hover);
    }
  }

  &[data-mobile='true'] {
    padding: var(--spacing-xl) var(--spacing-md);
    gap: var(--spacing-2xl);
    flex-flow: column-reverse;

    img {
      align-self: center;
    }

    span {
      align-self: flex-start;
    }
  }
`;
