import { ReactNode } from 'react';

import { List } from './PromptTemplateTagListRoot.styles';

export type PromptTemplateTagListRootProps = {
  children: ReactNode;
  mobile?: boolean;
};

export function PromptTemplateTagListRoot({ children, mobile }: PromptTemplateTagListRootProps) {
  return <List data-mobile={mobile}>{children}</List>;
}
