import { RMText } from '@/components/RMText/RMText';
import { PromptTemplateTag } from '@/services/cms/prompt-template-tags/prompt-template-tags.types';

import { Item } from './PromptTemplateTagListItem.styles';

type PromptTemplateTagListItemProps = {
  id?: string;
  tag: PromptTemplateTag;
  isSelected?: boolean;
  onSelectTag: (tag: PromptTemplateTag) => void;
  mobile?: boolean;
};

export function PromptTemplateTagListItem({
  id,
  tag,
  isSelected,
  onSelectTag,
  mobile,
}: PromptTemplateTagListItemProps) {
  return (
    <Item id={id} key={tag.value} onClick={() => onSelectTag(tag)} data-selected={isSelected} data-mobile={mobile}>
      <RMText type="sans" size={mobile ? 's' : 'm'} bold color="on-surface-secondary">
        {tag.title}
      </RMText>

      <img src={tag.imageUrl} />
    </Item>
  );
}
