import { styled } from '@linaria/react';

export const Hero = styled.div`
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-2xl);
  background-color: var(--surface-dim);
  border: 1px solid var(--border-hairline);
  border-radius: var(--radius-smooth);
  width: 100%;

  body[data-mobile='true'] & {
    padding: var(--spacing-md);
  }
`;

export const HeroCategory = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  gap: var(--spacing-lg);

  span {
    letter-spacing: 1.4px;
  }

  body[data-mobile='true'] & {
    gap: var(--spacing-sm);
  }
`;

export const HeroImage = styled.img`
  height: 10rem;
  width: 10rem;

  body[data-mobile='true'] & {
    height: 8.5rem;
    width: 8.5rem;
  }
`;
