import { useCallback, useEffect, useMemo, useState } from 'react';

import { RecordingStorytellerSelectionDialog } from '@/modules/recording/components/RecordingStorytellerSelectionDialog/RecordingStorytellerSelectionDialog';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { usePersonQuery } from '@/services/api/person';
import { useProjectQuery } from '@/services/api/project';

export interface RecordingStorytellerSelectionDialogContainerProps {
  projectId?: string | null;
  open: boolean;
  onClose?: () => void;
  onContinue: (recorderPersonId: string) => void;
}

export function RecordingStorytellerSelectionDialogContainer({
  projectId,
  open,
  onClose,
  onContinue,
}: RecordingStorytellerSelectionDialogContainerProps) {
  const projectQuery = useProjectQuery(projectId);
  const project = projectQuery.data;
  const storytellerQuery = usePersonQuery(project?.subjectPersonIds?.[0]);
  const user = useUser();
  const userPersonQuery = usePersonQuery(user?.personId);

  const options = useMemo(() => {
    if (userPersonQuery.data == null || storytellerQuery.data == null) {
      return [];
    }

    return [
      {
        personId: userPersonQuery.data.id,
        name: userPersonQuery.data.name?.full ?? '',
      },
      {
        personId: storytellerQuery.data.id,
        name: storytellerQuery.data.name?.full ?? '',
      },
    ];
  }, [userPersonQuery.data, storytellerQuery.data]);
  const [selectedOption, setSelectedOption] = useState(options[0]?.personId);

  const handleContinue = useCallback(async () => {
    return onContinue(selectedOption);
  }, [onContinue, selectedOption]);

  useEffect(() => {
    if (selectedOption == null) {
      setSelectedOption(options[0]?.personId);
    }
  }, [selectedOption, options]);

  useEffect(() => {
    if (open) {
      setSelectedOption(options[0]?.personId);
    }
  }, [open, options]);

  return (
    <RecordingStorytellerSelectionDialog
      open={open}
      onClose={onClose}
      onOptionSelected={(option) => setSelectedOption(option)}
      onContinue={handleContinue}
      options={options}
      selectedOption={selectedOption}
    />
  );
}
